
import { defineComponent, ref, onBeforeMount, watch, onMounted } from 'vue';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
  CampTable,
  CampTableTh,
  CampTableTd,
  CampEmptyListFeedbackV2
} from '@/components';
import { campHandleEmptyFields } from '@/composables/DataValidation';
import { useLoaderStore } from "@/store/LoaderStore";
import { useCompanyStore } from '@/store/CompanyStore';
import { useStoreStore } from '@/store/StoreStore';
import { useAuthStore } from '@/store/AuthStore';
import { useFilterMainStore } from '@/store/FilterMainStore'
import ptBr from "element-plus/lib/locale/lang/pt-br";
import moment from 'moment';
import { TOptions, uploadMediaFromClient } from '@/services/AzureBlobService';
import ModalQuiz, { TQuestion, createAlternative } from '@/views/Training/Components/ModalQuiz.vue';
import MediaCardCore from '@/views/Training/Components/MediaCardCore.vue';
import { IMediaFile, initMediaFile } from './PutPage.vue';

type TYPE_TRAINING_GROUPS_OPTIONS = {
  COMPANY: {
    KEY: 1;
    LABEL: string;
  };
  STORE: {
    KEY: 2;
    LABEL: string;
  };
  COMPETITOR: {
    KEY: 3;
    LABEL: string;
  };
};

const TRAINING_GROUPS_OPTIONS: TYPE_TRAINING_GROUPS_OPTIONS = {
  COMPANY: {
    KEY: 1,
    LABEL: "Companhia"
  },
  STORE: {
    KEY: 2,
    LABEL: "Loja"
  },
  COMPETITOR: {
    KEY: 3,
    LABEL: "Vendedor"
  }
}

type TTrainingGroups = 1 | 2 | 3

function initQuestion():TQuestion {
  return {
    title: '',
    points: 0,
    alternatives: [ createAlternative() ]
  }
}

interface ITrainingData {
  id?: number,
  title: string,
  description: string,
  url: string,
  active: 0 | 1,
  points: number,
  initial_date: string,
  end_date: string
  id_user: number | string,
  id_company: number[] | string[] | null,
  id_store: number[] | string[] | null,
  id_competitor: number[] | string[] | null
}

interface ITrainingResponse {
  data: {
    error: boolean,
    message: string,
    data: any
    errorCode: string
  },
  status: number
}

interface ICreateTrainingResponse {
  data: {
    error: boolean,
    message: string,
    data: any
    errorCode: string
  },
  status: number
}

export default defineComponent({
  name: "TrainingPost",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
    ModalQuiz,
    CampTable,
    CampTableTh,
    CampTableTd,
    CampEmptyListFeedbackV2,
    MediaCardCore
  },
  setup() {
    // Variables
    const filterMainStore = useFilterMainStore()
    const isLoading = ref(true)
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const isRequiredField = ref(false)
    const infoMaxCharacters = ref<string | null>(null)
    const infoMaxCharactersWarning = ref<string | null>(null)
    const quizModalToggle = ref(false)
    const disabledBtnForm = ref(true)
    const isChangedQuestionList = ref(false)
    /** Company */
    const companyStore = useCompanyStore()
    /** Store */
    const storeStore = useStoreStore()
    /** User */
    const authStore = useAuthStore()
    /** Training */
    const activeStatus = ref(false)
    const trainingGroupsId = ref<TTrainingGroups>(authStore.isAdmUser ? TRAINING_GROUPS_OPTIONS.COMPANY.KEY : TRAINING_GROUPS_OPTIONS.STORE.KEY)
    const trainingGroupsList = [
      { key: TRAINING_GROUPS_OPTIONS.STORE.KEY, label: TRAINING_GROUPS_OPTIONS.STORE.LABEL },
      { key: TRAINING_GROUPS_OPTIONS.COMPETITOR.KEY, label: TRAINING_GROUPS_OPTIONS.COMPETITOR.LABEL }
    ]
    const trainingGroupsOptions = ref(authStore.isAdmUser ? [ { key: TRAINING_GROUPS_OPTIONS.COMPANY.KEY, label: TRAINING_GROUPS_OPTIONS.COMPANY.LABEL }, ...trainingGroupsList ] : [ ...trainingGroupsList ])
    const companyOptions = ref([ { key: companyStore.getId, label: companyStore.getCompany ? companyStore.getCompany.fantasy_name : "" } ])
    const storeOptions = ref<{ key: string | number | null, label: string }[]>([{ key: "", label: "" }])
    const competitorOptions: typeof storeOptions = ref([{ key: "", label: "" }])
    const currentDate = new Date()
    const maxDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
    const mediaFile = ref<IMediaFile>({ ...initMediaFile })
    const INIT_DATA: ITrainingData = {
      title: "",
      points: 10,
      description: "",
      url: "",
      initial_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      active: activeStatus.value ? 1 : 0,
      id_company: companyStore.getId ? [companyStore.getId] : null,
      id_store: null,
      id_competitor: null,
      id_user: authStore.getUser ? authStore.getUser.id : ""
    }
    const formModel = ref<ITrainingData>(INIT_DATA)
    /** Question */
    const question = ref<TQuestion >(initQuestion())
    const questionList = ref<TQuestion[]>([])
    const editQuestionId = ref(-1)
    /** Attached File List */
    const attachedFileList = ref<File[] | null>(null)

    // Functions
    async function getTrainingGroups() {
      formModel.value.id_company = null
      formModel.value.id_store = null
      formModel.value.id_competitor = null
      isLoading.value = true

      try {
        if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPANY.KEY) {
          const response = await axios.get(`/api/getCompany`)
          const { data } = response.data
          companyOptions.value = [ ...data.map(el => ({ key: el.id, label: el.fantasy_name })) ]
        }
        if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.STORE.KEY) {
          const response = await axios.get(`/api/getStore`)
          const { data } = response.data
          storeOptions.value = [ ...data.map(el => ({ key: el.id, label: el.fantasy_name + ' - ' + el.cnpj })) ]
        }
        if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPETITOR.KEY) {
          const response = await axios.get(`/api/getCompetitor`)
          const { data } = response.data
          competitorOptions.value = [ ...data.map(el => ({ key: el.id, label: el.name })) ]
        }
        isLoading.value = false
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response) {
            showTimeAlert(error.response?.data.message, "error")
          }else {
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
          }
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
      }
    }

    const resetWarning = () => isRequiredField.value = false

    /** Handle Attached File */
    function handleAttachedFileChange(event: Event) {
      const fileInput = event.target as HTMLInputElement;
      const file: File | null | undefined = fileInput.files?.[0];

      if(!file)
        return

      if(!(Array.isArray(attachedFileList.value)))
        attachedFileList.value = new Array()

      if(attachedFileList.value.find(el => el.name === file.name))
        return showTimeAlert('Arquivo já inserido!', 'error')
      
      attachedFileList.value.push(file)
      fileInput.value = '';
    }

    /** Date */
    const handleDisabledDateMin = (time: Date) => time.getTime() < maxDate.getTime()

    const handleDisabledDateMax = (time: Date) => time.getTime() < moment(formModel.value.initial_date).valueOf()

    const handleFixRangeDate = () => {
      if(moment(formModel.value.initial_date).valueOf() >= moment(formModel.value.end_date).valueOf())
        formModel.value.end_date = moment(formModel.value.initial_date).add(1, 'hour').format()
      if(!formModel.value.initial_date || !formModel.value.end_date) {
        formModel.value.initial_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        formModel.value.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
    }

    /** Size Desc */
    function handleSizeDesc(max = 255) {
      const length = formModel.value.description.length
      infoMaxCharacters.value = `${length}/${max}`
      if(length > max)
        infoMaxCharactersWarning.value = `Máximo de ${max} caracteres atingido!`
      else
        infoMaxCharactersWarning.value = null
    }

    /** Handle Open Media Modal */
    const handleQuizModalToggle = () => quizModalToggle.value = !quizModalToggle.value

    const resetQuizModal = () => {
      question.value = initQuestion()
      editQuestionId.value = -1
    }

    const handleQuestion = (value: { question: TQuestion, editQuestionId: number }) => {
      isChangedQuestionList.value = !isChangedQuestionList.value
      if(value.editQuestionId < 0) {
        questionList.value.push(value.question)
      }
      else {
        questionList.value[value.editQuestionId] = value.question
        resetQuizModal()
      }
    }

    const handleEditQuestion = (edit: TQuestion, id: number) => {
      question.value = edit
      editQuestionId.value = id
      handleQuizModalToggle()
    }

    const handleRemoveQuestion = (i: number) => {
      resetQuizModal()
      questionList.value = questionList.value.map((el, j) => (i !== j ? { ...el } : { ...el, del: true }))
    }

    const handleUndoRemoveQuestion = (i: number) => {
      resetQuizModal()
      questionList.value = questionList.value.map((el, j) => (i !== j ? { ...el } : { ...el, del: false }))
    }

    async function handleCompetitorTraining(id_training: number) {
      let id_company: number[] | string[] | null = null
      let id_store: number[] | string[] | null = null
      let id_competitor: number[] | string[] | null = null

      if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPANY.KEY)
        id_company = formModel.value.id_company

      if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.STORE.KEY)
        id_store = formModel.value.id_store
      
      if(trainingGroupsId.value === TRAINING_GROUPS_OPTIONS.COMPETITOR.KEY)
        id_competitor = formModel.value.id_competitor

      const bodyToPost = {
        id_training,
        id_company,
        id_store,
        id_competitor
      }

      if(
        (id_company && Array.isArray(id_company) && id_company.length)
        ||
        (id_store && Array.isArray(id_store) && id_store.length)
        ||
        (id_competitor && Array.isArray(id_competitor) && id_competitor.length)
      ) {
        try {
          const response: ICreateTrainingResponse = await axios.post(`/api/postCompetitorTraining`, bodyToPost)
        } catch (error) {
          if(error instanceof AxiosError) {
            if(error.response)
              showTimeAlert(error.response?.data.message, "error")
            else
              showTimeAlert("Algo deu errado!", "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        }
      }
    }

    async function onSubmitForm() {
      isRequiredField.value = false

      if(infoMaxCharactersWarning.value){
        showTimeAlert("Verifique os campos obrigatórios.")
        return true
      }

      formModel.value.initial_date = moment(formModel.value.initial_date).format('YYYY-MM-DD HH:mm:ss')
      formModel.value.end_date = moment(formModel.value.end_date).format('YYYY-MM-DD HH:mm:ss')
      formModel.value.active = activeStatus.value ? 1 : 0
      const { id_company, id_store, id_competitor, url, ...requiredFields } = formModel.value

      if(campHandleEmptyFields(requiredFields)) {
        showTimeAlert("Verifique os campos obrigatórios.")
        isRequiredField.value = true
        return true
      }

      const bodyToPost = {
        url,
        ...requiredFields,
        questionList: questionList.value.filter(el => !el.del)
      }

      if(mediaFile.value.file) {
        const options: TOptions = {
          blobHTTPHeaders: {
            blobContentType: mediaFile.value.contentType
          }
        };
        try {
          loaderStore.open()
          const { request } = (await uploadMediaFromClient(mediaFile.value.file, mediaFile.value.blobName, options))._response
          bodyToPost.url = request.url
        } catch (error) {
          loaderStore.close()
          return showTimeAlert('Erro ao salvar media, tente mais tarde!', 'error')
        }
      }

      if(mediaFile.value.youtubeURL)
        bodyToPost.url = mediaFile.value.youtubeURL

      try {
        loaderStore.open()
        const response: ITrainingResponse = await axios.post(`/api/postTraining`, { ...bodyToPost })
        handleCompetitorTraining(response.data.data.training.id)
        showTimeAlert("Treinamento criado com sucesso!")
        formModel.value = { ...INIT_DATA }
        router.push("/treinamentos")
      } catch (error) {
        if(error instanceof AxiosError) {
          if(error.response)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        } else {
          showTimeAlert("Algo deu errado, tente novamente mais tarde", "error")
        }
        loaderStore.close()
      }
    }

    /** Handle Filter Modal */
    function handleInitFilterModal() {
      if(!companyStore.getId) {
        filterMainStore.hideModal()
        filterMainStore.showModal()
      }
    }

    const handleUpdatedMediaFile = (emittedObject: IMediaFile) => mediaFile.value = emittedObject

    const handleDisabledSendButton = () => {
      disabledBtnForm.value = true
      const check1 =
        (Array.isArray(formModel.value.id_company) && formModel.value.id_company.length) ||
        (Array.isArray(formModel.value.id_store) && formModel.value.id_store.length) ||
        (Array.isArray(formModel.value.id_competitor) && formModel.value.id_competitor.length)
      const check2 =
        (questionList.value.filter(el => !el.del)).length
      if(check1 && check2)
        disabledBtnForm.value = false
    }

    // Life cycles - Actions
    onBeforeMount(() => getTrainingGroups())

    onMounted(() => handleInitFilterModal())

    watch(() => { trainingGroupsId.value, companyStore.getId }, async () => await getTrainingGroups(), { deep: true })

    watch(() => formModel.value.description, () => handleSizeDesc())

    watch(() => { formModel.value.initial_date, formModel.value.end_date }, () => handleFixRangeDate(), { deep: true })

    watch(
      () => {
        formModel.value.id_company,
        formModel.value.id_store,
        formModel.value.id_competitor,
        isChangedQuestionList.value
      },
      () => handleDisabledSendButton(),
      { deep: true }
    )

    return {
      isRequiredField,
      isLoading,
      companyStore,
      storeStore,
      resetWarning,
      formModel,
      infoMaxCharacters,
      infoMaxCharactersWarning,
      trainingGroupsId,
      trainingGroupsOptions,
      activeStatus,
      ptBr,
      TRAINING_GROUPS_OPTIONS,
      companyOptions,
      storeOptions,
      competitorOptions,
      handleDisabledDateMin,
      handleDisabledDateMax,
      onSubmitForm,
      disabledBtnForm,
      handleQuizModalToggle,
      quizModalToggle,
      question,
      questionList,
      editQuestionId,
      resetQuizModal,
      handleQuestion,
      handleEditQuestion,
      handleRemoveQuestion,
      handleUndoRemoveQuestion,
      handleAttachedFileChange,
      mediaFile,
      handleUpdatedMediaFile,
      attachedFileList,
      moment,
    }
  }
})
